import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as yup from 'yup'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Icon from '@mui/material/Icon'

import Paypal from './PaypalIcon'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import checkImg from './images/check.png'
import securityImg from './images/security.png'
import api from '../networking/api'

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(1, 'Name should be of minimum 1 characters length')
    .max(100, 'Name should not be of maximum 100 characters length')
    .required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
})

export default function (props) {
  const { productID } = props
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()
  const [orderID, setOrderID] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [paypalError, setPaypalError] = useState(null)

  const stripeForm = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      setStripeError(null)
      createOrder(values.name, values.email, productID)
        .then((id) => {
          setOrderID(id)
          handleStripeSubmit(id, values.name, values.email)
            .then((token) => history.push('/success/' + token))
            .catch((err) => {
              setLoading(false)
              setStripeError(err)
            })
        })
        .catch((err) => {
          setLoading(false)
          setStripeError(err)
        })
    },
  })

  const paypalForm = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      setPaypalError(null)
      createOrder(values.name, values.email, productID)
        .then((id) => {
          setOrderID(id)
          api
            .createPaymentIntent(id, 'paypal')
            .then(({ approval_link }) => {
              window.location.assign(approval_link)
            })
            .catch((err) => {
              setLoading(false)
              setPaypalError(err)
            })
        })
        .catch((err) => {
          setLoading(false)
          setPaypalError(err)
        })
    },
  })

  const createOrder = async (name, email, productID) => {
    if (orderID) {
      return orderID
    } else {
      const res = await api.createOrder(productID, name, email)
      setOrderID(res.id)
      return res.id
    }
  }

  const handleStripeSubmit = async (id, name, email) => {
    const { secret } = await api.createPaymentIntent(id, 'stripe')

    if (!secret) throw new Error('Could not create payment intent')

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
            email,
          },
        },
      })

    if (stripeError) {
      setStripeError(stripeError.message)
      throw new Error(stripeError.message)
    }
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      try {
        return await api.getOrderToken(id)
      } catch (e) {
        throw new Error(
          'Payment is successfully processed, but some other error occurred: ' +
            e.message
        )
      }
    }

    throw new Error('Failed to process payment request')
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{ height: '100%' }}
    >
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon sx={{ ml: { md: -4 }, mr: 2 }}>
          <img src={checkImg} alt="" style={{ width: '100%' }} />
        </Icon>
        <Typography variant="subtitle2" style={{ color: '#888' }}>
          After you checkout you'll receive an email from us with more
          information about the launch.
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ m: 2 }} />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email Address *"
          variant="outlined"
          size="small"
          value={stripeForm.values.email}
          onChange={(e) => {
            stripeForm.handleChange(e)
            paypalForm.handleChange(e)
          }}
          error={
            (stripeForm.touched.email && Boolean(stripeForm.errors.email)) ||
            (paypalForm.touched.email && Boolean(paypalForm.errors.email))
          }
          helperText={
            (stripeForm.touched.email && stripeForm.errors.email) ||
            (paypalForm.touched.email && paypalForm.errors.email)
          }
        />
        <Box sx={{ m: 2 }} />
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Cardholder Name *"
          variant="outlined"
          size="small"
          value={stripeForm.values.name}
          onChange={(e) => {
            stripeForm.handleChange(e)
            paypalForm.handleChange(e)
          }}
          error={
            (stripeForm.touched.name && Boolean(stripeForm.errors.name)) ||
            (paypalForm.touched.name && Boolean(paypalForm.errors.name))
          }
          helperText={
            (stripeForm.touched.name && stripeForm.errors.name) ||
            (paypalForm.touched.name && paypalForm.errors.name)
          }
        />
      </Grid>
      <Grid item>
        <Box sx={{ m: 3 }} />
        <Box>
          <CardNumberElement id="card" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CardExpiryElement id="card" />
            </Grid>
            <Grid item xs={6}>
              <CardCvcElement id="card" />
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            variant="contained"
            onClick={stripeForm.handleSubmit}
            loading={loading}
          >
            Complete Reservation
          </LoadingButton>
          {stripeError && (
            <Typography variant="caption" sx={{ color: 'red' }}>
              {stripeError.message}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ m: 3 }} />
        <Divider>or Pay By</Divider>
        <Box sx={{ m: 3 }} />
        <LoadingButton
          fullWidth
          variant="contained"
          color="secondary"
          onClick={paypalForm.handleSubmit}
          loading={loading}
        >
          <Paypal />
        </LoadingButton>
        {paypalError && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            {paypalError}
          </Typography>
        )}
        <Box sx={{ m: 3 }} />
      </Grid>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ p: 2 }}>
            <img src={securityImg} alt="" width="80" />
          </Box>
          <Typography variant="subtitle2" sx={{ color: '#888' }}>
            We take your privacy seriously. This website is protected by a 256
            bit SSL security encryption.
          </Typography>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {(() => {
            let result = []
            for (let i = 1; i <= 6; i++) {
              result.push(
                // eslint-disable-next-line no-undef
                <img src={require(`./images/brand-${i}.png`)} alt="" key={i} width="40" style={{ padding: 5 }}/>
              )
            }
            return result
          })()}
        </Box>
      </Grid>
    </Grid>
  )
}
