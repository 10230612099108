import axios from 'axios'

const host = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACKEND_HOST,
})

export default {
  getProduct: (id) => host.get('/products/' + id).then((res) => res.data),

  getSuccessPageData: (token) =>
    host.get('/orders/success/' + token).then((res) => res.data),

  createOrder: (product_id, name, email) =>
    host.post('/orders', { product_id, name, email }).then((res) => res.data),

  createPaymentIntent: (order_id, type) =>
    host.post('/payment/intent', { order_id, type }).then((res) => res.data),

  getOrderToken: (order_id) =>
    host
      .get('/orders/token?order_id=' + order_id)
      .then((res) => res.data.token),
}
