import React, { Component } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Product, Success, NotFound } from './Product'
import { Terms, Policy } from './Agreements'

var theme = {
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
  },
  palette: {
    secondary: {
      main: '#F8B932',
    },
  },
  // palette: {
  //   primary: {
  //     main: '#F8B932',
  //   },
  //   secondary: {
  //     main: '#191A1A',
  //   },
  // },
}

export class App extends Component {
  state = {
    theme: createTheme(theme),
  }

  updateTheme = (data) => {
    theme.typography.fontFamily[0] = data.font
    theme.palette.primary.main = data.primary_color
    theme.palette.secondary.main = data.secondary_color
    this.setState({ theme: createTheme(theme) })
  }

  render() {
    const { theme, data } = this.state
    if (data === null) {
      return <div></div>
    }

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ m: { xs: 2, md: 10 } }} />
        <Router>
          <Switch>
            <Route
              path="/product/:id"
              render={() => <Product />}
              onLoad={this.updateTheme}
            />
            <Route
              path="/success/:id"
              render={() => <Success />}
              onLoad={this.updateTheme}
            />
            <Route
              path="/terms"
              render={() => <Terms />}
              onLoad={this.updateTheme}
            />
            <Route
              path="/policy"
              render={() => <Policy />}
              onLoad={this.updateTheme}
            />
            <Route
              path="/"
              render={() => <NotFound />}
              onLoad={this.updateTheme}
            />
          </Switch>
        </Router>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            mt: 5,
            mb: 5,
          }}
        >
          {/* <Link href="/terms" variant="body2" color="text.secondary">
            Terms &amp; Conditions
          </Link>
          <Box sx={{ m: 1 }} />
          <Link href="/policy" variant="body2" color="text.secondary">
            Privacy Policy
          </Link> */}
        </Box>
      </ThemeProvider>
    )
  }
}
