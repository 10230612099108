import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'

import NotFound from './NotFound'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import api from '../networking/api'
import checkImg from './images/check.png'

export default function () {
  const [product, setProduct] = useState(null)
  const { id } = useParams()

  if (!id) return <NotFound />

  useEffect(() => {
    api
      .getSuccessPageData(id)
      .then((res) => {
        setProduct(res)
        document.title = res.title
        document.getElementById('favicon').href = res.properties.logo_url
        if (res.properties.facebook_pixel_id) {
          ReactPixel.init(res.properties.facebook_pixel_id, null, {
            autoConfig: true,
          })
          ReactPixel.pageView()
          ReactPixel.track('Purchase', {
            currency: 'USD',
            value: res.price / 100,
          })
        }

        if (res.properties.google_analytics_id) {
          ReactGA.initialize(res.properties.google_analytics_id)
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
      })
      .catch((err) => setProduct(err))
  }, [id])

  if (!product) return <></>
  if (!product.id) return <NotFound />

  return (
    <Container size="md" sx={{ textAlign: 'center' }}>
      <Grid container alignItems="center">
        <Grid item>
          <Avatar
            sx={{ width: 24, height: 24 }}
            alt=""
            src={product.properties.logo_url}
          />
        </Grid>
        <Grid item>
          <Box sx={{ m: 0.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {product.properties.company_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center">
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Box sx={{ m: { sx: 2, md: 5 } }} />
            </Grid>
            <Grid item sx={{ m: 2 }}>
              <Typography variant="h3">
                {product.properties.complete_title}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon sx={{ ml: { md: -4 }, mr: 2 }}>
                <img src={checkImg} alt="" style={{ width: '100%' }} />
              </Icon>
              <Typography variant="subtitle2" style={{ color: '#888' }}>
                After you checkout you'll receive an email from us with more
                information about the launch.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() =>
                  (window.location.href = product.properties.complete_link_url)
                }
              >
                {product.properties.complete_link_title}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{product.properties.complete_desc}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ m: 5 }}>
            <img
              alt=""
              src={product.properties.complete_image_url}
              style={{ borderRadius: 2, width: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
