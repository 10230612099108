import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import numeral from 'numeral'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'

import NotFound from './NotFound'
import PaymentForm from './PaymentForm'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import api from '../networking/api'

export default function () {
  const [product, setProduct] = useState(null)
  const { id } = useParams()

  if (!id) return <NotFound />

  useEffect(() => {
    api
      .getProduct(id)
      .then((res) => {
        setProduct(res)
        document.title = res.title
        document.getElementById('favicon').href = res.properties.logo_url
        if (res.properties.facebook_pixel_id) {
          ReactPixel.init(res.properties.facebook_pixel_id, null, {
            autoConfig: true,
          })
          ReactPixel.pageView()
        }
        if (res.properties.google_analytics_id) {
          ReactGA.initialize(res.properties.google_analytics_id)
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
      })
      .catch((err) => setProduct(err))
  }, [id])

  if (!product) return <></>
  if (!product.id) return <NotFound />

  return (
    <Container size="md">
      <Box sx={{ m: { xs: 0, md: 5 } }} />
      <Grid container>
        <Grid item xs={12} md={5} sx={{ p: 2 }}>
          <Grid container alignItems="center">
            <Grid item>
              <Avatar
                sx={{ width: 24, height: 24 }}
                alt=""
                src={product.properties.logo_url}
              />
            </Grid>
            <Grid item>
              <Box sx={{ m: 0.5 }} />
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {product.properties.company_name}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ m: 5 }} />
          <Box
            sx={{
              width: '60%',
              borderRadius: 2,
              overflow: 'hidden',
              display: { md: 'none' },
              m: '0 auto',
            }}
          >
            <img
              alt=""
              src={product.properties.image_url}
              style={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="overline">{product.title}</Typography>
            <Typography variant="h3">
              ${numeral(product.price / 100).format('0,0.00')}
            </Typography>
            <Box sx={{ m: 2 }} />
            <Typography>{product.properties.desc}</Typography>
          </Box>

          <Box sx={{ m: 2 }} />
          <Box
            sx={{
              width: '100%',
              borderRadius: 2,
              overflow: 'hidden',
              display: { md: 'block', xs: 'none' },
            }}
          >
            <img
              alt=""
              src={product.properties.image_url}
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
        <Grid item md={2}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ height: '100%', width: 0, m: '0 auto' }}
          />
        </Grid>
        <Grid item xs={12} md={5} sx={{ p: 2 }}>
          <PaymentForm productID={product.tid} />
        </Grid>
      </Grid>
    </Container>
  )
}
