import React from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export default function () {
  console.log(window.location.hostname)

  return (
    <Container size="md">
      <Grid container direction="column" alignItems="center" mt={15}>
        <Typography variant="h1">404</Typography>
        <Typography variant="h2" mt={5}>Not Found</Typography>
        <Typography>Sorry, cannot found the url.</Typography>
      </Grid>
    </Container>
  )
}
