import React from 'react'

import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

export default function () {
  document.title = 'Privacy Policy'

  return (
    <Container maxWidth="md">
      <Typography sx={{ textAlign: 'center' }}>Privacy Policy</Typography>
      <Typography variant="h4" sx={{ textAlign: 'center', m: 3 }}>
        DATA PROCESSING &amp; PRIVACY POLICY
      </Typography>

      <Typography sx={{m: 1}}>
        This policy sets out the basis on which we collect and process your
        personal data through your use of our website, our digital applications
        (Apps), and/or signing up for newsletters, competitions and other
        marketing activities either in-store or online.
      </Typography>
      <Typography sx={{m: 1}}>
        Any changes we make to this policy in the future will be posted on this
        page and, if appropriate, notified to you by email so please check this
        page regularly.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        INFORMATION ABOUT US
      </Typography>
      <Typography sx={{m: 1}}>
        Higizmos is the controller responsible for your personal data (referred
        to as "we", “us” or “our” in this privacy policy). If you have any
        questions about this privacy policy, the use of your personal
        information, or your dealings with our website or our Apps, you can
        contact us at hi@higizmos.com.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        YOUR PERSONAL DATA
      </Typography>

      <Typography variant="h6" sx={{ textAlign: 'center', m: 2 }}>
        Information You Provide to Us
      </Typography>
      <Typography sx={{m: 1}}>
        You will provide some or all of the following information to us when you
        purchase goods from our website, register a customer account with us,
        use one of our Apps, contact us, or sign up for newsletters and other
        marketing activities, either in one of our shops or online:
      </Typography>
      <ul>
        <li>
          <Typography sx={{m: 1}}>
            Your Identity and Contact Details -includes your name, email
            address, home postal address/billing address, delivery address (if
            different), and your contact telephone number;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Your Payment Details - your bank details and payment card details,
            however your payment transactions will be encrypted by the acquiring
            bank so we will not retain any payment card details submitted by
            you;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Your Profile Data - if you register a customer account, this
            includes the profile you create to identify yourself when connecting
            to our website and Apps (including your username and password) and
            other data about purchases and your personal preferences; and
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Additional Personal Details - At your option, you can also provide
            additional personal information to us such as your date of birth,
            gender, product preferences and interests. This additional
            information would only be collected and stored by us for the purpose
            of analyzing sales to help us understand and improve our business
            and for the purpose of any direct marketing which you may have
            expressly given your consent to.
          </Typography>
        </li>
      </ul>
      <Typography sx={{m: 1}}>
        Please Note: It is important that the personal data we hold about you is
        accurate and up to date. Please keep us informed if your personal data
        changes during your relationship with us.
      </Typography>
      <Typography sx={{m: 1}}>
        We do not collect any categories of sensitive personal data and this
        website is not intended for children so we do not knowingly collect data
        relating to children.
      </Typography>

      <Typography variant="h6" sx={{ textAlign: 'center', m: 2 }}>
        Information We Collect When You Use Our Website or Our Apps
      </Typography>
      <Typography sx={{m: 1}}>
        As you interact with our website and Apps, we also automatically collect
        usage and technical data (described below) about your equipment,
        browsing actions and patterns. We collect this personal data by using
        cookies, server logs and other similar technologies.
      </Typography>
      <Typography sx={{m: 1}}>
        Usage Data - includes details of products and services you have
        purchased from us, and information about how you use our website and our
        Apps;
      </Typography>
      <Typography sx={{m: 1}}>
        By using our website, you (the visitor) agree to allow third parties to
        process your IP address, in order to determine your location for the
        purpose of currency conversion. You also agree to have that currency
        stored in a session cookie in your browser (a temporary cookie which
        gets automatically removed when you close your browser). We do this in
        order for the selected currency to remain selected and consistent when
        browsing our website so that the prices can convert to your (the
        visitor) local currency.
      </Typography>
      <Typography sx={{m: 1}}>Technical Data - includes:</Typography>
      <ul>
        <li>
          <Typography sx={{m: 1}}>
            your Internet protocol (IP) address, browser type and version, time
            zone setting and location, browser plug-in types and versions,
            operating system and platform, and other technology on the devices
            you use to access this website;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            information about your visit, including the full Uniform Resource
            Locators (URL) clickstream to, through and from our website
            (including date and time); products you viewed or searched for; page
            response times, download errors, length of visits to certain pages,
            page interaction information (such as scrolling, clicks, and
            mouse-overs), and methods used to browse away from the page, mobile
            applications traffic data, weblogs and other communication data ;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            if you use a mobile device to access our website or one of our Apps,
            the type of mobile device you use, a unique device identifier (for
            example, your device’s IMEI number, the MAC address of the device’s
            wireless network interface, or the mobile phone number used by the
            device), mobile network information, your mobile operating system,
            the type of mobile browser you use, time zone settings);
          </Typography>
        </li>
      </ul>

      <Typography sx={{m: 1}}>
        Other - We may also receive information from third parties we work
        closely with such as online fashion retail platforms, delivery services,
        credit reference agencies and fraud prevention agencies.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        WHAT WE USE YOUR PERSONAL DATA FOR
      </Typography>
      <Typography sx={{m: 1}}>
        Data protection law in the UE allows us to collect, use, store and
        transfer personal information if we have one or more of the following
        purposes for doing so:
      </Typography>

      <ul>
        <li>
          <Typography sx={{m: 1}}>To fulfill a contract with you; or</Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Where necessary to comply with a legal or regulatory obligation; or
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Where necessary for our legitimate interests (or those of a third
            party) provided your interests and fundamental rights do not
            override these interests); or
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            For other reasons where you have expressly consented to those
            purposes.
          </Typography>
        </li>
      </ul>

      <Typography sx={{m: 1}}>
        Below are the ways we will use your personal data to fulfill your
        contract and/or for our legitimate interests:
      </Typography>

      <ul>
        <li>
          <Typography sx={{m: 1}}>
            Processing your order, including taking payment, supplying products
            and any after sales services such as cancellations and returns;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>identify verification and credit checking;</Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            keeping you updated on the progress of your order, we will usually
            communicate with you about your order by email, but we or our
            couriers may also contact you by phone;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            where you have registered with us, processing and administering your
            account;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            processing information required for competition, promotion or survey
            entries by you;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            allowing you to participate in interactive features of our service
            if you choose to do so;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            analyzing sales to help us understand and improve our business;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            administration, analysis and assessment of our website and Apps and
            their performance, to understand and develop our business and for
            the improvement and optimization of our website and any Apps; and
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            as part of our efforts to keep our website and our Apps safe and
            secure.
          </Typography>
        </li>
      </ul>

      <Typography sx={{m: 1}}>
        Direct Marketing We need your consent for direct marketing
        communications. If you have expressly given your consent to receive
        direct marketing from us, whether via our website, Apps or in store, we
        may use the information you have given us for:
      </Typography>

      <ul>
        <li>
          <Typography sx={{m: 1}}>
            delivering direct marketing, advertising and updates from us to you,
            such as telling you about forthcoming events, products, services and
            offers and updates and information about us;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            measuring and understanding the effectiveness of advertising we
            serve to you and others; and
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            making suggestions and recommendations to you and other users of our
            website and our Apps about goods or services that may interest you
            or them.
          </Typography>
        </li>
      </ul>
      <Typography sx={{m: 1}}>
        At any time you can opt out of direct marketing clicking on the
        unsubscribe button at the end of the email.
      </Typography>
      <Typography sx={{m: 1}}>
        Cookies: We need your consent for our use of cookies. You have the right
        to withdraw your consent.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        WHO WE SHARE YOUR PERSONAL DATA WITH
      </Typography>
      <Typography sx={{m: 1}}>
        We may share your information within the Higizmos Team and with our
        selected third parties for the purposes referred to above. For example:
      </Typography>

      <ul>
        <li>
          <Typography sx={{m: 1}}>
            Payment Acquiring Services to process your payment;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Delivery and Courier Companies to supply your order;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Credit Reference & Fraud Prevention Agencies for credit checking and
            identity verification;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Analytics and search engine providers that assist us in the
            improvement and optimization of our website and Apps;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Build and hosting service providers that help us to maintain the
            software that runs our website and our Apps. They provide us with
            reporting statistics, and serve cookies on our behalf to enable any
            website or Apps personalization and sign-in functionality.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Where you opted in to direct marketing, third-party advertisers,
            affiliate marketing agencies and advertising networks engaged by us
            to select and serve relevant communications to you on our behalf.
          </Typography>
        </li>
      </ul>

      <Typography sx={{m: 1}}>
        We require all third parties engaged by us to respect the security of
        your personal data and to treat it in accordance with the law. Such
        third parties are only allowed to process your personal data in
        accordance with our instructions, and not for their own purposes.
      </Typography>
      <Typography sx={{m: 1}}>
        From time to time, we may ask you if you would like to be contacted by
        other selected third parties, such as part of a joint promotion or a
        collaboration. If we do this, we will also provide you with a link to
        that third party’s privacy policy to help you decide whether or not you
        wish to be contacted by them. If you do consent to this (by an express
        opt in), that third party would be the data controller in respect of any
        activity by them that you have consented to.
      </Typography>

      <Typography variant="h6" sx={{ textAlign: 'center', m: 2 }}>
        Links to Other Organisations
      </Typography>
      <Typography sx={{m: 1}}>
        Our website and/or our Apps may, from time to time, contain links to and
        from websites of our partner networks, advertisers and affiliates. If
        you follow a link these websites have their own privacy policies and
        that we do not accept any responsibility or liability for these
        policies. Please check these policies before you submit any personal
        data to these websites.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        OPTING OUT OF MARKETING AND PREFERENCE UPDATES
      </Typography>
      <Typography sx={{m: 1}}>
        If you no longer wish to receive direct marketing and updates
        from Higizmos you can unsubscribe at any time by clicking on the link in
        the footer of the email or using the ‘unsubscribe from all’ link within
        the email preference centre accessible through the emails. You can also
        amend your preferences within the email preference centre.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        SECURITY OF YOUR PERSONAL DATA
      </Typography>
      <Typography sx={{m: 1}}>
        Our website and Apps have appropriate security measures in place to
        prevent your personal data from being accidentally lost, used or
        accessed in an unauthorised way, altered or disclosed. All information
        you provide to us is stored on secure servers. We have also put in place
        procedures to deal with any suspected personal data breach.
      </Typography>
      <Typography sx={{m: 1}}>
        Where you have chosen a password which enables you to access certain
        parts of our website or our Apps, you are responsible for keeping this
        password confidential. We ask you to choose an effective, secure
        password and not to share your password with anyone.
      </Typography>
      <Typography sx={{m: 1}}>
        We recommend only connecting to secure wireless networks that you trust
        to reduce the risk of unauthorised people intercepting your online
        activity. Beware of the risks of using public wifi.
      </Typography>
      <Typography sx={{m: 1}}>
        If you receive an email purporting to be from Higizmos asking for
        personal data, please do not respond to the email but please let us know
        that this has happened so we can check whether the email is genuine.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        OUTSIDE EEA - DATA STORAGE &amp; TRANSFER
      </Typography>
      <Typography sx={{m: 1}}>
        The data that we collect from you may be transferred to, and stored at,
        a destination outside the European Economic Area (“EEA”) to work with
        members of the Higizmos and third parties who we use to help us carry
        out the purposes referred to above or to comply with a legal duty.
      </Typography>
      <Typography sx={{m: 1}}>
        Whenever we transfer your personal data out of the EEA, we will make
        sure an adequate level of protection is afforded to it by using one of
        the following safeguards:
      </Typography>
      <ul>
        <li>
          <Typography sx={{m: 1}}>
            We may transfer your personal data to countries that have privacy
            laws which give the same level of protection for personal data as
            the EEA;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            We may use contract clauses with recipients which mean they must
            protect your personal data with the same level of protection as it
            has in the EEA;
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            We may transfer personal data to organization if they are part of
            the Privacy Shield framework which requires them to provide similar
            protection to personal data shared between European countries and
            the US.
          </Typography>
        </li>
      </ul>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        HOW LONG WE KEEP YOUR PERSONAL DATA
      </Typography>
      <Typography sx={{m: 1}}>
        We will keep your data for as long as you are a customer and thereafter
        we may keep it for longer in order to respond to any questions or
        complaints and to maintain necessary records to satisfy legal,
        accounting or reporting requirements. We may also keep your data for
        research or statistical purposes.
      </Typography>

      <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>
        YOUR RIGHTS
      </Typography>
      <Typography sx={{m: 1}}>
        You have the right to object to our use of your personal information, or
        to ask us to delete, remove or stop using it if there is no need for us
        to keep it. Please let us know if you think we should not be using it,
        but please be aware that there may be legal or other official reasons
        why we need to keep or use your information.
      </Typography>
      <Typography sx={{m: 1}}>
        Your rights under data protection laws in relation to your personal data
        are briefly summarized below, and you can exercise these rights by
        emailing us at hi@higizmos.com.
      </Typography>

      <ul>
        <li>
          <Typography sx={{m: 1}}>
            Request access to your personal data (commonly known as a “data
            subject access request”). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Request correction of the personal data that we hold about you. This
            enables you to have any incomplete or inaccurate data we hold about
            you corrected, though we may need to verify the accuracy of the new
            data you provide to us.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Request erasure of your personal data. This enables you to ask us to
            delete or remove personal data where there is no good reason for us
            continuing to process it. Please note, however, that we may not
            always be able to comply with your request of erasure for specific
            legal reasons which we will tell you about, if applicable, after
            receiving your request.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Object to processing of your personal data where we are relying on a
            legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Request restriction of processing of your personal data. You can ask
            us to suspend the processing of your personal data in the following
            scenarios: (a) if you want us to establish the data’s accuracy; (b)
            where our use of the data is unlawful but you do not want us to
            erase it; © where you need us to hold the data even if we no longer
            require it as you need it to establish, exercise or defend legal
            claims; or (d) you have objected to our use of your data but we need
            to verify whether we have overriding legitimate grounds to use it.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Please note that this right only applies to automated
            information which you initially provided consent for us to use or
            where we used the information to perform a contract with you.
          </Typography>
        </li>
        <li>
          <Typography sx={{m: 1}}>
            Withdraw consent at any time if we are relying on your consent to
            process your personal data (such as direct marketing and cookies).
            This won’t affect any processing already carried out before you
            withdraw your consent or processing under other grounds. If you
            withdraw your consent, we may not be able to provide certain
            products or services to you.
          </Typography>
        </li>
      </ul>

      <Typography sx={{m: 1}}>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </Typography>
      <Typography sx={{m: 1}}>
        You will not have to pay a fee to access your personal data and we try
        to respond to all requests within one month.
      </Typography>
      <Typography sx={{m: 1}}>
        If you have any questions or require any more information please contact
        us at hi@higizmos.com
      </Typography>
    </Container>
  )
}
